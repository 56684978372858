import React from "react";

import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";

const Page = () => {
  return (
    <LessonLayout
      current_step={"13b"}
      lesson={"Lesson 4"}
      color={"hs"}
      total_count={15}
      module_title="What Could Happen?"
    >
      <ContentWrapper>
        <H2 underline>Step 13b</H2>

        <p>This article was published in <em className="italic">Scientific American</em>. It tells the story of a woman who went to a doctor for a medical procedure. As you read, think about the benefits and risks that this woman faced.</p>
        <Button
          action="secondary"
          className="mt-5"
          color="indigo"
          href="/files/hs/lesson_4/L4_Reading_3_case_study.pdf"
          icon="download"
          title="Go to reading"
        >
          Go to reading
        </Button>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;


